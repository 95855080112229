import React from "react";

class InquiryComplete extends React.Component {

  styles = {
    container: {
      color: "#282828",
      width: "375px",
      margin: "0 auto",
      boxSizing: "border-box",
      padding: "40px 20px 50px",
      fontFamily: "Hiragino Kaku Gothic Pro"
    },
    h1: {
      fontSize: "24px",
      fontWeight: "bold"
    },
    exp: {
      fontSize: "16px",
      marginTop: "10px"
    },
    back: {
      width: "100%",
      height: "50px",
      display: "block",
      margin: "82px auto 0",
      backgroundColor: "#FFFFFF",
      border: "2px solid #6B8E23",
      textDecoration: "none",
      textAlign: "center",
      lineHeight: "50px",
      borderRadius: "4px",
      color: "#6B8E23",
      fontSize: "20px",
      cursor: "pointer"
    }
  };

  render() {
    const { root_path } = this.props;
    return (
      <div style={this.styles.container}>
        <h1 style={this.styles.h1}>お問い合わせ受け付け完了</h1>
        <div className="exp" style={this.styles.exp}>
          担当者よりご連絡差し上げますので、しばらくお待ちくださいますようお願い致します。
        </div>
        <a className="back" href={root_path} style={this.styles.back}>トップページへ</a>
      </div>
    );
  }
}

export default InquiryComplete;
