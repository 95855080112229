import React from "react";
import appIcon from "../../images/app_icon.png";

class Invitation extends React.Component {
  state = {
    submitting: false,
    error: null
  };
  styles = {
    container: {
      width: "375px",
      margin: "0 auto",
      fontFamily: "Hiragino Kaku Gothic Pro"
    },
    iconArea: {
      width: "100%",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FCFAED"
    },
    icon: {
      width: "120px",
      height: "120px"
    },
    exp: {
      color: "#282828",
      fontSize: "21px",
      lineHeight: "30px",
      margin: "0 15px"
    },
    hr: {
      margin: "29px 0 19px",
      borderWidth: "0.5px",
      backgroundColor: "#B8BFA9"
    },
    label: {
      color: "#6B8E23",
      fontSize: "18px",
      margin: "0 15px"
    },
    formRow: {
      marginTop: "6px",
      display: "flex",
      height: "50px",
      margin: "0 15px"
    },
    numberInput: {
      width: "200px",
      WebkitAppearance: "none",
      padding: "0 20px",
      fontSize: "24px",
      border: "solid 1px #B8BFA9",
      borderRadius: "4px",
      backgroundColor: "#F6F6F6"
    },
    submit: {
      height: "50px",
      margin: "0 auto",
      backgroundColor: "#6B8E23",
      WebkitAppearance: "none",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      fontSize: "24px",
      cursor: "pointer"
    },
    submitDisabled: {
      backgroundColor: "#C3CCC2",
      cursor: "default"
    },
    submitResend: {
      border: "2px solid #6B8E23",
      backgroundColor: "#FFFFFF",
      color: "#6B8E23"
    },
    submitResendDisabled: {
      borderColor: "#C3CCC2",
      color: "#C3CCC2",
      cursor: "default"
    },
    modalBackground: {
      position: "fixed",
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#00000026"
    },
    modalContent: {
      width: "85%",
      maxHeight: "85%",
      padding: "20px 15px",
      borderRadius: "4px",
      position: "absolute",
      margin: "auto",
      top: "50%",
      left: "0",
      right: "0",
      transform: "translateY(-50%)",
      backgroundColor: "#FFFFFF"
    },
    modalMessage: {
      color: "#DF5B5B",
      fontSize: "21px",
      maxHeight: "50%"
    },
    cancelBtn: {
      height: "50px",
      width: "100%",
      marginTop: "30px",
      WebkitAppearance: "none",
      borderRadius: "4px",
      border: "2px solid #6B8E23",
      backgroundColor: "#FFFFFF",
      color: "#6B8E23",
      fontSize: "24px",
      cursor: "pointer"
    }
  };

  constructor(props) {
    super(props);
    this.state.error = this.props.error;
  }

  render() {
    const {
      user_name,
      mobile,
      csrf_params,
      csrf_token,
      is_confirm,
      send_sms_path,
      confirm_sms_path
    } = this.props;
    return (
      <div style={this.styles.container}>
        <div className="iconArea" style={this.styles.iconArea}>
          <img className="icon" style={this.styles.icon} src={appIcon} />
        </div>
        <div className="exp" style={this.styles.exp}>
          そなサポは、{user_name}様があなたへ引き継ぎたいと思った資産を、{user_name}
          様からあなたへのビデオメッセージと共にお伝えする無料サービスです。
          携帯番号にショートメール（SMS）で認証コードを送信し、届いた4ケタの認証コードを入力し、認証を完了してください。
        </div>
        <hr
          style={{
            ...this.styles.hr,
            marginTop: "29px"
          }}
        />
        <form
          id="send_sms"
          action={send_sms_path}
          method="post"
          onSubmit={e => {
            this.setState({
              submitting: true
            });
          }}
        >
          <input type="hidden" name={csrf_params} value={csrf_token} />
          <label style={this.styles.label}>携帯番号</label>
          <div style={this.styles.formRow}>
            <input
              style={this.styles.numberInput}
              type="number"
              pattern="\d*"
              name="sms_confirmation[mobile]"
              id="sms_confirmation_mobile"
              readOnly={is_confirm}
              value={mobile}
            />
            <input
              style={(() => {
                if (is_confirm) {
                  return this.state.submitting
                    ? {
                        ...this.styles.submit,
                        ...this.styles.submitResend,
                        ...this.styles.submitResendDisabled,
                        width: "95px",
                        marginLeft: "10px"
                      }
                    : {
                        ...this.styles.submit,
                        ...this.styles.submitResend,
                        width: "95px",
                        marginLeft: "10px"
                      };
                } else {
                  return this.state.submitting
                    ? {
                        ...this.styles.submit,
                        ...this.styles.submitDisabled,
                        width: "95px",
                        marginLeft: "10px"
                      }
                    : {
                        ...this.styles.submit,
                        width: "95px",
                        marginLeft: "10px"
                      };
                }
              })()}
              type="submit"
              value={is_confirm ? "再送" : "送信"}
            />
          </div>
        </form>
        <hr style={this.styles.hr} />
        <form
          id="sms_confirmation"
          action={confirm_sms_path}
          method="post"
          onSubmit={e => {
            this.setState({
              submitting: true
            });
          }}
        >
          <input type="hidden" name={csrf_params} value={csrf_token} />
          <input type="hidden" name="sms_confirmation[mobile]" value={mobile} />
          <label style={this.styles.label}>認証コード</label>
          <div style={this.styles.formRow}>
            <input
              style={{
                ...this.styles.numberInput,
                width: "120px"
              }}
              type="number"
              pattern="\d*"
              name="sms_confirmation[confirmation_code]"
              id="sms_confirmation_confirmation_code"
            />
          </div>
          <hr style={this.styles.hr} />
          <input
            style={
              this.state.submitting || !is_confirm
                ? {
                    ...this.styles.submit,
                    ...this.styles.submitDisabled,
                    width: "345px",
                    margin: "15px"
                  }
                : {
                    ...this.styles.submit,
                    width: "345px",
                    margin: "15px"
                  }
            }
            type="submit"
            value="完了"
            disabled={
              this.state.submitting || !is_confirm ? "disabled" : undefined
            }
          />
        </form>
        {(() => {
          if (this.state.error !== null) {
            return (
              <div style={this.styles.modalBackground}>
                <div style={this.styles.modalContent}>
                  <div style={this.styles.modalMessage}>{this.state.error}</div>
                  <button
                    style={this.styles.cancelBtn}
                    onClick={() => {
                      this.setState({ ...this.state, error: null });
                    }}
                  >
                    戻る
                  </button>
                </div>
              </div>
            );
          }
        })()}
      </div>
    );
  }
}

export default Invitation;
