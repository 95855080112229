import React from "react";

class Inquiry extends React.Component {
  state = {
    submitting: false,
    inquiry: {},
    errors: []
  };
  styles = {
    container: {
      color: "#282828",
      width: "375px",
      margin: "0 auto",
      boxSizing: "border-box",
      padding: "40px 20px 50px",
      fontFamily: "Hiragino Kaku Gothic Pro"
    },
    h1: {
      fontSize: "24px",
      fontWeight: "bold"
    },
    exp: {
      fontSize: "16px",
      marginTop: "10px"
    },
    hr: {
      width: "100%",
      margin: "20px 0",
      borderWidth: "0.5px",
      backgroundColor: "#DDDDDD"
    },
    label: {
      display: "block",
      fontSize: "18px",
      marginBottom: "10px"
    },
    field: {
      width: "100%",
      height: "50px",
      boxSizing: "border-box",
      display: "block",
      WebkitAppearance: "none",
      padding: "0 10px",
      fontSize: "16px",
      border: "solid 1px #DDDDDD",
      borderRadius: "4px",
      backgroundColor: "#F4F4F4"
    },
    fieldError: {
      borderColor: "#F17373",
      backgroundColor: "#FEF3F3"
    },
    textArea: {
      width: "100%",
      height: "400px",
      boxSizing: "border-box",
      WebkitAppearance: "none",
      padding: "10px",
      fontSize: "16px",
      border: "solid 1px #DDDDDD",
      borderRadius: "4px",
      backgroundColor: "#F4F4F4"
    },
    textAreaError: {
      borderColor: "#F17373",
      backgroundColor: "#FEF3F3"
    },
    errorText: {
      color: "#F17373"
    },
    submit: {
      width: "100%",
      height: "50px",
      margin: "10 auto 0",
      backgroundColor: "#6B8E23",
      WebkitAppearance: "none",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      fontSize: "20px",
      cursor: "pointer"
    },
    submitDisabled: {
      backgroundColor: "#C3CCC2",
      cursor: "default"
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      submitting: true
    });
    const form = event.target;
    const data = new FormData(form);

    fetch(this.props.post_path, {
      method: "POST",
      body: data
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        this.setState({ submitting: false, errors: json.errors ? json.errors : []});
        if (json.redirect_to) {
          location.href = json.redirect_to;
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ submitting: false });
      });
  }

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { csrf_params, csrf_token, post_path } = this.props;
    return (
      <div style={this.styles.container}>
        <h1 style={this.styles.h1}>お問い合わせ</h1>
        <div className="exp" style={this.styles.exp}>
          以下の項目にご入力の上、「送信」ボタンをクリックしてください。
        </div>
        <hr
          style={{
            ...this.styles.hr
          }}
        />
        <form
          id="inquiry"
          action={post_path}
          method="post"
          data-remote="true"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name={csrf_params} value={csrf_token} />
          <label style={this.styles.label}>お名前（必須）</label>
          <input
            style={
              this.state.errors.name
                ? { ...this.styles.field, ...this.styles.fieldError }
                : this.styles.field
            }
            type="text"
            name="name"
            id="name"
            defaultValue={this.state.inquiry.name}
          />
          {(() => {
            if (this.state.errors.name) {
              return (
                <p className="error" style={this.styles.errorText}>
                  {this.state.errors.name}
                </p>
              );
            }
          })()}
          <hr
            style={{
              ...this.styles.hr
            }}
          />
          <label style={this.styles.label}>メールアドレス（必須）</label>
          <input
            style={
              this.state.errors.email
                ? { ...this.styles.field, ...this.styles.fieldError }
                : this.styles.field
            }
            type="text"
            name="email"
            id="email"
            defaultValue={this.state.inquiry.email}
          />
          {(() => {
            if (this.state.errors.email) {
              return (
                <p className="error" style={this.styles.errorText}>
                  {this.state.errors.email}
                </p>
              );
            }
          })()}
          <hr
            style={{
              ...this.styles.hr
            }}
          />
          <label style={this.styles.label}>お問い合わせ内容（必須）</label>
          <textarea
            style={
              this.state.errors.inquiry_text
                ? { ...this.styles.textArea, ...this.styles.textAreaError }
                : this.styles.textArea
            }
            name="inquiry_text"
            id="inquiry_text"
            defaultValue={this.state.inquiry.inquiry_text}
          />
          {(() => {
            if (this.state.errors.inquiry_text) {
              return (
                <p className="error" style={this.styles.errorText}>
                  {this.state.errors.inquiry_text}
                </p>
              );
            }
          })()}
          <hr
            style={{
              ...this.styles.hr
            }}
          />
          <input
            style={(() => {
              return this.state.submitting
                ? {
                    ...this.styles.submit,
                    ...this.styles.submitDisabled
                  }
                : {
                    ...this.styles.submit
                  };
            })()}
            type="submit"
            value="送信"
          />
        </form>
      </div>
    );
  }
}

export default Inquiry;
