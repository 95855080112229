import React from "react";
import iosStoreIcon from "../../images/btn_app_store.png";
import androidStoreIcon from "../../images/btn_google_play.png";
import appIcon from "../../images/app_icon.png";

class Invitation extends React.Component {
  state = {
    submitting: false
  };
  styles = {
    container: {
      width: "375px",
      margin: "0 auto",
      fontFamily: "Hiragino Kaku Gothic Pro"
    },
    iconArea: {
      width: "100%",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FCFAED"
    },
    icon: {
      width: "120px",
      height: "120px"
    },
    exp: {
      color: "#282828",
      fontSize: "21px",
      lineHeight: "30px",
      margin: "29px 15px 48px"
    },
    storeIcon: {
      display: "block",
      margin: "29px auto 0",
      cursor: "pointer"
    },
    iosStoreIcon: {
      width: "164px"
    },
    androidStoreIcon: {
      width: "200px"
    },
    img: {
      width: "100%"
    }
  };
  render() {
    const { ios_url, android_url } = this.props;
    return (
      <div style={this.styles.container}>
        <div className="iconArea" style={this.styles.iconArea}>
          <img className="icon" style={this.styles.icon} src={appIcon} />
        </div>
        <div className="exp" style={this.styles.exp}>
          認証が完了しました。<br/>
          以下のボタンを押して、アプリをインストールしてそなサポをご利用ください。
        </div>
        <a
          href={ios_url}
          style={{ ...this.styles.storeIcon, ...this.styles.iosStoreIcon }}
        >
          <img src={iosStoreIcon} style={this.styles.img} />
        </a>
        <a
          href={android_url}
          style={{
            ...this.styles.storeIcon,
            ...this.styles.androidStoreIcon
          }}
        >
          <img src={androidStoreIcon} style={this.styles.img} />
        </a>
      </div>
    );
  }
}

export default Invitation;
